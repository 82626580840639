<template>
  <Modal v-model="modal1" width="1000" :title="$t('key1000906')" footer-hide>
    <Input v-model.trim="keywords" @input="changeInput" clearable :placeholder="$t('key1000907')" style="margin: 10px;width: 300px;"></Input>
    <Table border :columns="columns1" max-height="400" :data="data2"></Table>
  </Modal>
</template>

<script>
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';

export default {
  name: 'authorizedStore',
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      modal1: false,
      data2: [],
      keywords: '',
      columns1: [
        {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000908'),
          key: 'accountCode',
          align: 'center',
          tree: true
        },
        {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000909'),
          align: 'center',
          key: 'accountName'
        },
        {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000493'),
          align: 'center',
          key: 'status',
          render(h, params) {
            if (params.row.authAccountTokens) {
              let data = params.row.authAccountTokens;
              if (data) {
                return h('div', data.map(i => {
                  return h('p', [
                    h('span', i.authName),
                    h('span', {
                      style: {
                        color: i.status === 1 ? 'green' : 'red'
                      }
                    }, i.status === 1 ? aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000910') : aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000911')),
                    i.authDescription ? h('Poptip', {
                      props: {
                        transfer: true,
                        'word-wrap': true,
                        trigger: 'hover',
                        content: i.authDescription
                      }
                    }, [
                      h('Icon', {
                        props: {
                          size: 16,
                          type: 'ios-help-circle'
                        }
                      })]) : ''
                  ]);
                }));
              }
            }
          }
        },
        {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000278'),
          align: 'center',
          key: 'opts',
          width: 110,
          render(h, params) {
            let ymsPlatformAccountId = params.row.ymsPlatformAccountId;
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: ymsPlatformAccountId ? aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000912') : aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000913'),
                    show: true,
                    clickFn: () => {
                      v.addAuthStore('0', params.row.authAccountId, params.row.ymsPlatformAccountId);
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    v.optionBtnWidth.push(value);
                    params.column.width = Math.max(...v.optionBtnWidth);
                    v.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ],
      data1: [],
    };
  },
  created() {

  },
  props: {},
  computed: {},
  methods: {
    changeInput() {
      this.data2 = this.data1.filter(i => {
        return i.accountCode.includes(this.keywords) || i.accountName.includes(this.keywords);
      });
    },
    addAuthStore(code, authAccountId, ymsPlatformAccountId) {
      let v = this;
      v.axios.get(api.get_ymsPlatformAccount_callback, {
        params: {
          code: code,
          authAccountId: authAccountId,
          ymsPlatformAccountId: ymsPlatformAccountId
        }
      }).then(response => {
        if (response.data.code === 0) {
          v.$Message.success(aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000672'));
          v.modal1 = false;
          v.$emit('getList');
        }
      });
    },
    getAuthorizedStore(params) {
      let v = this;
      v.axios.get(api.get_ymsPlatformAccount_queryAuthAccountList + '?platformId=' + params.platformId,
        {loading: true, loadingText: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000212')}).then(response => {
        if (response.data.code === 0) {
          v.modal1 = true;

          v.data1 = response.data.datas.map((i, index) => {
            i._parent = true;
            i.index = index + 'p';
            return i;
          });
          v.data2 = JSON.parse(JSON.stringify(v.data1));
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
